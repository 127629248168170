<template>
  <main class="main">
    <div class="main__up">
      <h1
        >{{ this.$t('MAIN.h1') }},<span>{{ this.$t('MAIN.tumar') }}</span
        >(не <span> {{ this.$t('MAIN.tumar') }}? </span>
        {{ this.$t('MAIN.logout') }})</h1
      >
      <p
        >{{ this.$t('MAIN.info.1') }}
        <a href="#">{{ this.$t('MAIN.info.2') }}</a
        >, {{ this.$t('MAIN.info.3') }}
        <a href="#">{{ this.$t('MAIN.info.4') }}</a
        >, <br />
        {{ this.$t('MAIN.info.5') }}
        <a href="#">{{ this.$t('MAIN.info.6') }}</a></p
      >
    </div>
    <div class="card__wrapper">
      <div class="card__inner">
        <div class="card__content">
          <i class="policy-icon"></i>
          <span>Lorem ipsum</span>
        </div>
        <div class="card__content">
          <i class="policy-icon"></i>
          <span>Lorem ipsum</span>
        </div>
        <div class="card__content">
          <i class="policy-icon"></i>
          <span>Lorem ipsum</span>
        </div>
        <div class="card__content">
          <i class="policy-icon"></i>
          <span>Lorem ipsum</span>
        </div>
        <div class="card__content">
          <i class="policy-icon"></i>
          <span>Lorem ipsum</span>
        </div>
        <div class="card__content">
          <i class="policy-icon"></i>
          <span>Lorem ipsum</span>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.main {
  padding: 0 23px;
  &__up {
    background-color: #ffffff;
    padding: 41px 20px 40px 75px;
    margin-top: 21px;
    margin-bottom: 25px;
    // @media screen and (max-width: 400px) {
    //   padding: 20px;
    // }
    @media (max-width: 575px) {
      padding: 0;
      background: none !important;
    }

    h1 {
      font-family: 'Alegreya Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      display: flex;
      align-items: center;
      color: #848484;
      margin-bottom: 11px;
      letter-spacing: 0.3px;
      span {
        font-weight: 700;
        white-space: pre;
      }
    }

    p {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.5px;
      color: #848484;
    }

    a {
      font-weight: 600;
      color: #31343c;
    }
  }
}
.policy-icon {
  background-image: url('/img/icons/policy-icon.svg');
  display: inline-block;
  width: 87px;
  height: 87px;
  margin-right: 36px;
  @media (max-width: 575px) {
    margin: 0;
  }
}
.card__content {
  width: 100%;
  height: 146px;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  @media (max-width: 575px) {
    flex-direction: column;
  }
}
.card__inner {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 146px);
  grid-gap: 20px;
}
@media screen and (max-width: 768px) {
  .card__inner {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 400px) {
  .card__inner {
    grid-template-columns: repeat(1, 1fr);
  }
}
.card__content span {
  width: 50px;
  font-family: 'Alegreya Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #666666;
  overflow-wrap: break-word;
  @media (max-width: 575px) {
    width: 100px;
  }
}
</style>
